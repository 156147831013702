<template>
  <ValidationObserver
    ref="formItemDetail"
  >
    <form
      novalidate
      @submit.prevent
    >
      <b-row
        class="border-bottom py-4"
      >

        <b-col
          cols="12"
          md="4"
        >
          <b-row>

            <b-col
              cols="12"
              md="6"
            >
              <ValidationProvider
                #default="{ errors }"
                name="purchase date"
                :vid="vPURCHASEDATE"
                :rules="rPURCHASEDATE"
              >
                <b-form-group>
                  <label for="item_detail_purchase_date">
                    <strong>
                      Purchase Date
                    </strong>
                  </label>
                  <b-input
                    id="item_detail_purchase_date"
                    v-model="composition.purchase_date"
                    type="date"
                    placeholder="enter purchase date"
                    autocomplete="off"
                    :state="errors.length > 0 ? false : null"
                    :disabled="updating || composition.is_removed === 1 || state.busy"
                  />
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col
              cols="12"
              md="6"
            >
              <ValidationProvider
                #default="{ errors }"
                name="original cost"
                :vid="vORIGINALCOST"
                rules="required"
              >
                <b-form-group>
                  <label for="item_detail_original_cost">
                    <strong>
                      Cost
                    </strong>
                  </label>
                  <b-input
                    id="item_detail_original_cost"
                    v-model="composition.original_cost"
                    type="number"
                    placeholder="enter original cost"
                    autocomplete="off"
                    step="0.01"
                    :state="errors.length > 0 ? false : null"
                    :disabled="updating || composition.is_removed === 1 || state.busy"
                  />
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>
          </b-row>
        </b-col>

        <b-col
          cols="12"
          md="4"
        >
          <ValidationProvider
            #default="{ errors }"
            name="supplier"
            :vid="vSUPPLIER"
            :rules="rSUPPLIER"
          >
            <b-form-group>
              <label for="item_detail_supplier">
                <strong>
                  Supplier
                </strong>
              </label>
              <v-select
                id="item_detail_supplier"
                v-model="selected.supplier"
                type="text"
                label="supplier_name"
                placeholder="search supplier here"
                :options="suppliers"
                :class="[errors.length > 0 ? 'is-invalid' : null]"
                :disabled="updating || composition.is_removed === 1 || state.busy || fetching.suppliers"
                :loading="fetching.suppliers"
              >
                <template #option="{ supplier_name, supplier_address }">
                  <div class="d-flex flex-column">
                    <strong>{{ supplier_name }}</strong>
                    <span>{{ supplier_address }}</span>
                  </div>
                </template>
                <template #no-options="">
                  no available supplier
                </template>
              </v-select>
              <div
                v-if="errors.length > 0"
                class="invalid-feedback"
              >
                <span>{{ errors[0] }}</span>
              </div>
            </b-form-group>
          </ValidationProvider>
        </b-col>

        <b-col
          cols="12"
          md="4"
        >
          <ValidationProvider
            #default="{ errors }"
            name="brand"
            :vid="vBRAND"
            :rules="rBRAND"
          >
            <b-form-group>
              <label for="item_detail_brand">
                <strong>
                  Brand
                </strong>
              </label>
              <v-select
                id="item_detail_brand"
                v-model="selected.brand"
                type="text"
                label="brand_name"
                placeholder="search brand here"
                :options="brands"
                :class="[errors.length > 0 ? 'is-invalid' : null]"
                :disabled="updating || composition.is_removed === 1 || state.busy || fetching.brands"
                :loading="fetching.brands"
              >
                <template #option="{ brand_name }">
                  <div class="d-flex flex-column">
                    <strong>{{ brand_name }}</strong>
                  </div>
                </template>
                <template #no-options="">
                  no available brand
                </template>
              </v-select>
              <div
                v-if="errors.length > 0"
                class="invalid-feedback"
              >
                <span>{{ errors[0] }}</span>
              </div>
            </b-form-group>
          </ValidationProvider>
        </b-col>

        <b-col
          cols="12"
          md="4"
        >
          <b-row>

            <b-col
              cols="12"
              md="6"
            >
              <b-form-group>
                <label for="item_detail_type_name">
                  <strong>
                    Type
                  </strong>
                </label>
                <b-input
                  id="item_detail_type_name"
                  v-model="composition.type_name"
                  disabled
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              md="6"
            >
              <b-form-group>
                <label for="item_detail_category">
                  <strong>
                    Category
                  </strong>
                </label>
                <b-input
                  id="item_detail_category"
                  v-model="composition.category_name"
                  disabled
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>

        <b-col
          cols="12"
          md="4"
        >
          <ValidationProvider
            #default="{ errors }"
            name="serial number"
            :vid="vSERIALNUMBER"
            :rules="rSERIALNUMBER"
          >
            <b-form-group>
              <label for="item_detail_serial_number">
                <strong>
                  Serial Number
                </strong>
              </label>
              <b-input
                id="item_detail_serial_number"
                v-model="composition.serial_number"
                type="text"
                placeholder="enter serial number"
                autocomplete="off"
                :state="errors.length > 0 ? false : null"
                :disabled="updating || composition.is_removed === 1 || state.busy"
              />
              <div
                v-if="errors.length > 0"
                class="invalid-feedback"
              >
                <span>{{ errors[0] }}</span>
              </div>
            </b-form-group>
          </ValidationProvider>
        </b-col>

        <b-col
          cols="12"
          md="4"
        >
          <ValidationProvider
            #default="{ errors }"
            name="name"
            :vid="vITEMDETAILNAME"
            :rules="rITEMDETAILNAME"
          >
            <b-form-group>
              <label for="item_detail_item_name">
                <strong>
                  Name
                </strong>
              </label>
              <b-input
                id="item_detail_item_name"
                v-model="composition.item_detail_name"
                type="text"
                placeholder="enter name"
                autocomplete="off"
                :state="errors.length > 0 ? false : null"
                :disabled="updating || composition.is_removed === 1 || state.busy"
              />
              <div
                v-if="errors.length > 0"
                class="invalid-feedback"
              >
                <span>{{ errors[0] }}</span>
              </div>
            </b-form-group>
          </ValidationProvider>
        </b-col>

        <b-col
          cols="12"
        >
          <ValidationProvider
            #default="{ errors }"
            name="specifications"
            :vid="vSPECIFICATIONS"
            rules="max:500"
          >
            <b-form-group>
              <label for="item_detail_specifications">
                <strong>
                  Specifications
                </strong>
              </label>
              <b-textarea
                id="item_detail_specifications"
                v-model="composition.specifications"
                rows="3"
                max-rows="12"
                placeholder="enter specifications"
                :state="errors.length > 0 ? false : null"
                :disabled="updating || composition.is_removed === 1 || state.busy"
              />
              <div
                v-if="errors.length > 0"
                class="invalid-feedback"
              >
                <span>{{ errors[0] }}</span>
              </div>
            </b-form-group>
          </ValidationProvider>
        </b-col>

        <b-col
          v-if="state.editing"
          cols="12"
        >
          <div class="d-flex justify-content-between">
            <div>
              <div
                class="form-group"
              >
                <b-form-checkbox
                  id="is_active"
                  v-model="composition.active"
                  name="is_active"
                  value="1"
                  unchecked-value="0"
                  :disabled="state.busy"
                  switch
                >
                  Is Active?
                </b-form-checkbox>
              </div>
            </div>
            <div class="mb-3">
              <b-overlay
                :show="updating"
                rounded
                opacity="0.6"
                spinner-small
                spinner-variant="primary"
                class="d-inline-block"
              >
                <b-button
                  variant="success"
                  :disabled="updating || state.busy"
                  @click="onActionTaken(false)"
                >
                  Update
                </b-button>
              </b-overlay>
            </div>
          </div>
        </b-col>
        <b-col
          v-if="composition.is_required === 0"
        >
          <div class="d-flex justify-content-end">
            <div
              v-if="composition.is_removed"
              class="mb-3"
            >
              <b-button
                variant="success"
                :disabled="updating || state.busy"
                @click="onRestoreComposition"
              >
                Restore
              </b-button>
            </div>
            <div
              v-else
              class="mb-3"
            >
              <b-button
                variant="danger"
                :disabled="updating || state.busy"
                @click="onRemoveComposition"
              >
                Remove
              </b-button>
            </div>
          </div>
        </b-col>

      </b-row>

    </form>
  </ValidationObserver>
</template>
<script>
import { AdminItemDetailService } from '@/services'
import formatter from '@/mixins/formatter'
export default {
  name: 'ItemDetail',
  mixins: [formatter],
  props: {
    position: {
      type: Number,
      default: () => {
        return 0
      }
    },
    global: {
      type: Object,
      default: () => {
        return {
          item: null,
          purchase_date: null,
          supplier: null,
          brand: null
        }
      }
    },
    fetching: {
      type: Object,
      default: () => {
        return {
          suppliers: false,
          brands: false
        }
      }
    },
    state: {
      type: Object,
      default: () => {
        return {
          editing: false,
          busy: false
        }
      }
    },
    suppliers: {
      type: Array,
      default: () => {
        return []
      }
    },
    brands: {
      type: Array,
      default: () => {
        return []
      }
    },
    composition: {
      type: Object,
      default: () => {
        return {
          id: null,
          type: null,
          type_name: null,
          category: null,
          category_name: null,
          supplier: null,
          supplier_name: null,
          brand: null,
          brand_name: null,
          item: null,
          item_name: null,
          item_detail_name: null,
          serial_number: null,
          purchase_date: null,
          original_cost: 0.00,
          specifications: null,
          is_parent: 0,
          is_required: 1,
          is_removed: 0,
          active: 1
        }
      }
    }
  },
  data () {
    const supplier = { id: this.composition.supplier, supplier_name: this.composition.supplier_name, supplier_address: null }
    const brand = { id: this.composition.brand, brand_name: this.composition.brand_name }

    return {
      detaching: false,
      updating: false,
      selected: {
        supplier: this.state.editing ? supplier : null,
        brand: this.state.editing ? brand : null
      },
      itemDetail: {
        id: this.composition.id,
        type: this.composition.type,
        type_name: this.composition.type_name,
        category: this.composition.category,
        category_name: this.composition.category_name,
        supplier: this.composition.supplier,
        supplier_name: this.composition.supplier_name,
        brand: this.composition.brand,
        brand_name: this.composition.brand_name,
        item: this.composition.item,
        item_name: this.composition.item_name,
        item_detail_name: this.composition.item_detail_name,
        serial_number: this.composition.serial_number,
        purchase_date: this.composition.purchase_date,
        original_cost: this.composition.original_cost,
        specifications: this.composition.specifications,
        is_parent: Number(this.composition.is_parent),
        is_required: Number(this.composition.is_required),
        is_removed: Number(this.composition.is_removed),
        active: Number(this.composition.active)
      }
    }
  },
  computed: {
    rPURCHASEDATE () {
      return this.composition.is_required === 1 && this.composition.is_removed === 0 ? 'required' : ''
    },
    rSUPPLIER () {
      return this.composition.is_required === 1 && this.composition.is_removed === 0 ? 'required' : ''
    },
    rBRAND () {
      return this.composition.is_required === 1 && this.composition.is_removed === 0 ? 'required' : ''
    },
    rSERIALNUMBER () {
      return this.composition.is_required === 1 && this.composition.is_removed === 0 ? 'required|max:100' : 'max:100'
    },
    rITEMDETAILNAME () {
      return this.composition.is_required === 1 && this.composition.is_removed === 0 ? 'required|max:100' : 'max:100'
    },
    vSUPPLIER () {
      return this.composition.id ? 'supplier' : `compositions.${this.position}.supplier`
    },
    vBRAND () {
      return this.composition.id ? 'brand' : `compositions.${this.position}.brand`
    },
    vSERIALNUMBER () {
      return this.composition.id ? 'serial_number' : `compositions.${this.position}.serial_number`
    },
    vITEMDETAILNAME () {
      return this.composition.id ? 'item_detail_name' : `compositions.${this.position}.item_detail_name`
    },
    vPURCHASEDATE () {
      return this.composition.id ? 'purchase_date' : `compositions.${this.position}.purchase_date`
    },
    vORIGINALCOST () {
      return this.composition.id ? 'original_cost' : `compositions.${this.position}.original_cost`
    },
    vSPECIFICATIONS () {
      return this.composition.id ? 'specifications' : `compositions.${this.position}.specifications`
    }
  },
  watch: {

    'global.purchase_date' (purchaseDate) {
      if (this.composition.is_required === 1) {
        this.composition.purchase_date = purchaseDate
      }
    },

    'global.supplier' (supplier) {
      if (this.composition.is_required === 1) {
        this.selected.supplier = supplier
      }
    },

    'global.brand' (brand) {
      if (this.composition.is_required === 1) {
        this.selected.brand = brand
      }
    },

    'selected.supplier' (supplier) {
      this.composition.supplier = supplier?.id || null
      this.composition.supplier_name = supplier?.supplier_name || null
    },

    'selected.brand' (brand) {
      this.composition.brand = brand?.id || null
      this.composition.brand_name = brand?.brand_name || null
    }

  },
  methods: {

    onActionTaken (detached = false) {
      this.$refs.formItemDetail.validate().then(success => {
        if (success && this.state.editing) {
          let text = 'Do you really want to save changes?'

          if (detached) {
            text = 'Do your really want to detach this peripheral?'
          }

          this.$swal.fire({
            icon: 'question',
            title: 'Question',
            text: text,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Yes',
            cancelButtonColor: '#FF2929',
            showCancelButton: true,
            showLoaderOnConfirm: true,
            preConfirm: () => {
              this.state.busy = this.updating = true

              if (detached) {
                return this.onDetach()
              } else {
                return this.onUpdate()
              }
            },
            allowOutsideClick: () => !this.$swal.isLoading()
          })
        } else {
          this.$bvModal.msgBoxOk(
            'Oops! There were problem with your inputs.', {
              title: 'Validation Error',
              size: 'sm',
              buttonSize: 'sm',
              okVariant: 'danger',
              centered: true
            }
          )
        }
      })
    },

    async onDetach () {
      this.state.busy = this.detaching = true
      return new Promise(resolve => {
        AdminItemDetailService.detach(this.composition).then(({ data }) => {
          this.state.busy = this.detaching = false
          this.$swal.fire({
            icon: 'success',
            title: 'Successful',
            text: data.message,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Dismiss'
          }).then(() => {
            this.itemDetail = this.dataToComposition(data.itemDetail)
            this.detached(data.itemDetail)
          })
        }).catch(error => {
          this.state.busy = this.detaching = false
          this.$refs.formItemDetail.setErrors(error.message)
          resolve(error)
        })
      })
    },

    async onUpdate () {
      return new Promise(resolve => {
        AdminItemDetailService.put(this.composition).then(({ data }) => {
          this.state.busy = this.updating = false
          this.itemDetail = this.dataToComposition(data.itemDetail)
          this.$swal.fire({
            icon: 'success',
            title: 'Successful',
            text: data.message,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Dismiss'
          }).then(() => {
            this.updated(data.itemDetail)
          })
        }).catch(error => {
          this.state.busy = this.updating = false
          this.$refs.formItemDetail.setErrors(error.message)
          resolve(error)
        })
      })
    },

    showHistories () {
      this.$emit('showHistories', this.itemDetail)
    },

    updated (itemDetail) {
      this.$emit('updated', itemDetail, this.composition)
    },

    detached (itemDetail) {
      this.$emit('detached', itemDetail, this.composition)
    },

    showSwap () {
      this.$emit('showSwap', this.itemDetail)
    },

    onRemoveComposition () {
      this.composition.is_removed = 1

      this.composition.supplier = null
      this.composition.supplier_name = null
      this.composition.brand = null
      this.composition.brand_name = null
      this.composition.serial_number = null
      this.composition.purchase_date = null
      this.composition.original_cost = 0.00
      this.composition.specifications = null

      this.selected.brand = null
      this.selected.supplier = null

      this.$emit('onRemoveComposition', this.position, this.composition)
    },

    onRestoreComposition () {
      this.composition.is_removed = 0

      this.$emit('onRestoreComposition', this.position, this.composition)
    }
  }
}
</script>
